import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import { StyledButton } from "../../elements"
import { LongArrow } from "../../assets/svg"
import { PRODUCT_URLS } from "../../constants/urls"
import theme from "../../theme"
import "swiper/css/autoplay"

const PALETTE = theme.palette.custom

const PRODUCTS = [
  {
    color: PALETTE.blue,
    title: "Life and Live Secure Benefit",
    description: `Designed to ensure your legacy extends beyond your lifetime, this benefit guarantees your dependents are financially cared for in the event of your passing.`,
    link: PRODUCT_URLS.LIFE_AND_LIVE_BENEFIT,
  },
  {
    title: "Group Funeral Benefit",
    description: `Customisable group funeral cover designed for groups of between 10 – 200 principle members.`,
    link: PRODUCT_URLS.GROUP_FUNERAL_BENEFIT,
  },
  {
    title: "Comprehensive Funeral Benefit",
    description: `Guarantees peace of mind for you and your family, starting from as little as R112 per month<i>*</i>.`,
    link: PRODUCT_URLS.COMPREHENSIVE_FUNERAL_BENEFIT,
  },
  {
    title: "Platinum Plus Funeral Benefit",
    description: `Maximize benefits for your family with an enhanced funeral expense benefit.`,
    link: PRODUCT_URLS.PLATINUM_PLUS_FUNERAL_BENEFIT,
  },
  {
    title: "POPCRU 25 Funeral Benefit",
    description: `Exclusively available for POPCRU members, offering enhanced funeral expense benefits.`,
    link: PRODUCT_URLS.POPCRU_25_FUNERAL_BENEFIT,
  },
  {
    color: PALETTE.green,
    title: "Comprehensive Legal Insurance",
    description: `Affordable professional legal advice and services nationwide, covering criminal, civil, and labour matters, including contested & uncontested divorce.`,
    link: PRODUCT_URLS.COMPREHENSIVE_LEGAL_INSURANCE,
  },
]

const Legacy: React.FC = () => {
  const renderText = (index: number) => {
    const product = PRODUCTS[index]

    return (
      product && (
        <SlideContent>
          <BackDrop color={product.color || PALETTE.yellow} />
          <div>
            <div className="textContainer">
              <h3>{product.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: product.description }} />
            </div>
            <StyledButton
              buttonColor="#fff"
              altColor="#000"
              label="VIEW THIS PRODUCT"
              link={product.link}
              className="btnView"
            />
          </div>
        </SlideContent>
      )
    )
  }

  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  const [swiper, setSwiper] = useState({})

  return (
    <Container>
      <Left>
        <h2>Tailored Solutions for Your Peace of Mind</h2>
        <p>
          At Workerslife, we offer a diverse range of insurance products to
          address life&apos;s uncertainties. Our commitment to your financial
          security is unwavering.
          <br />
          <br /> Explore our suite of insurance solutions:
        </p>
      </Left>
      <Right>
        <Swiper
          loop={true}
          autoplay
          draggable={true}
          grabCursor={true}
          onInit={ev => {
            setSwiper(ev)
          }}
        >
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-1.png"
              {...ImageProperties}
            />
            {renderText(0)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-2.png"
              {...ImageProperties}
            />
            {renderText(1)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-3.png"
              {...ImageProperties}
            />
            {renderText(2)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-4.png"
              {...ImageProperties}
            />
            {renderText(3)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-5.png"
              {...ImageProperties}
            />
            {renderText(4)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-6.png"
              {...ImageProperties}
            />
            {renderText(5)}
          </SwiperSlide>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              swiper.slideNext()
            }}
          >
            <SwipeInstruction>
              <LongArrow />
              <span>SWIPE TO SEE OUR PRODUCTS</span>
            </SwipeInstruction>
          </div>
        </Swiper>
      </Right>
    </Container>
  )
}

export default Legacy

const Container = styled.div`
  display: grid;
  gap: 40px 120px;
  grid-template-rows: auto auto;
  margin: 0 auto;
  max-width: 1000px;

  > div {
    overflow-x: hidden;
  }

  .swiper {
    width: 100%;
  }

  .swiper-wrapper {
    box-sizing: border-box;
    height: inherit;
  }

  .swiper-slide {
    height: inherit;
    overflow-x: hidden;
    overflow-y: visible;
    width: 100%;
  }

  .gatsby-image-wrapper {
    display: none;
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.md}) and (max-width: ${props =>
      props.theme.maxBreakpoints.lg}),
    (min-width: ${props => props.theme.breakpoints.lg}) {
    .gatsby-image-wrapper {
      display: block;
      position: absolute;
      z-index: -1;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 415px 1fr;
    grid-template-rows: auto;
    max-width: inherit;
    padding: 0 0 0 80px;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 0;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const SlideContent = styled.div`
  height: 100%;
  padding: 50px 20px 200px 20px;
  position: relative;
  width: 100%;
  z-index: 2;

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .textContainer {
    color: #fff;
    margin-bottom: 50px;

    h3 {
      max-width: 300px;
    }

    p {
      white-space: pre-wrap;

      i {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-bottom: 280px;

    .btnView {
      display: flex;
      margin: auto 0 0 auto;
      width: max-content;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 80px 70px 280px 70px;
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.md}) and (max-width: ${props =>
      props.theme.maxBreakpoints.lg}),
    (min-width: ${props => props.theme.breakpoints.lg}) {
    height: calc(100% - 74px);
    margin: 74px 0 0 220px;
    padding: 80px 70px;
    width: calc(100% - 220px);
  }
`

const BackDrop = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.md}) and (max-width: ${props =>
      props.theme.maxBreakpoints.lg}),
    (min-width: ${props => props.theme.breakpoints.lg}) {
    border-radius: 4px;
  }
`

const SwipeInstruction = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 20px;
  line-height: 21px;
  pointer-events: none;
  z-index: 1;

  @media screen and (max-width: ${props => props.theme.maxBreakpoints.md}) {
    margin: 0 auto;
    max-width: 80%;

    right: 10%;
    bottom: 80px;
    position: absolute;

    span {
      color: #fff;
    }

    svg {
      filter: brightness(100);
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 48px 60px 0 0;
    position: relative;

    span {
      color: #000;
    }
  }
`
