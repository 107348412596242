import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../components"

const Customise: React.FC = () => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Customise Your Cover for Your Needs"
      color="#00427C"
      className="slider"
      description="At Workerslife, we recognise that your insurance needs are as unique as you are. That's why we offer a range of cover options that you can tailor to your current and future financial goals. With Workerslife, it's all about customization. You're in control."
      content={[
        {
          image: (
            <StaticImage
              src="../../assets/images/landing/challenges-1.png"
              {...ImageProperties}
            />
          ),
          topic: "Cover Options",
          label:
            "Choose from a wide range of cover options to build a policy that suits your specific needs. We provide flexibility like no other.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/landing/challenges-2.png"
              {...ImageProperties}
            />
          ),
          topic: "Financial Security",
          label:
            "Your family's financial security is non-negotiable. Our insurance benefits are designed to provide just that – complete peace of mind.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/landing/challenges-3.png"
              {...ImageProperties}
            />
          ),
          topic: "Policy Details",
          label:
            "We believe in transparency. All policy details are readily available, so you know exactly what you're getting.",
        },
      ]}
      reverse={true}
    />
  )
}

export default Customise
