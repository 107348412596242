import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import { ColorTiles } from "../../components"

import {
  CoinPileIcon,
  ContractIcon,
  MoneyHandIcon,
  PiggyBankIcon,
} from "../../assets/svg"

type Belief = {
  color: string
  icon: JSX.Element
  label: string
}

export const BELIEFS: Belief[] = [
  {
    color: "#A51B23",
    icon: <CoinPileIcon />,
    label: "A financial legacy that transcends your life.",
  },
  {
    color: "#F0A949",
    icon: <PiggyBankIcon />,
    label: "Dignity achieved through financial security.",
  },
  {
    color: "#2CA165",
    icon: <MoneyHandIcon />,
    label: "Free and fair access to financial solutions.",
  },
  {
    color: "#00427C",
    icon: <ContractIcon />,
    label: "Quick payment of claims.",
  },
]

const query = graphql`
  {
    bgImage: file(relativePath: { eq: "landing/beliefs.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Beliefs = (): JSX.Element => {
  const data = useStaticQuery(query)
  const imageData = data.bgImage.childImageSharp.fluid

  return (
    <ColorTiles
      title="At Workerslife we believe in"
      backgroundImage={imageData}
      tiles={BELIEFS.map(belief => ({
        tileType: "icon",
        ...belief,
      }))}
    />
  )
}

export default Beliefs
