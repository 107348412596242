import React from "react"
import styled from "styled-components"

import { StyledButton } from "../../elements"
import theme from "../../theme"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const PALETTE = theme.palette.custom

const query = graphql`
  {
    bgImage: file(relativePath: { eq: "landing/banner.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Banner = (): JSX.Element => {
  const data = useStaticQuery(query)
  const imageData = data.bgImage.childImageSharp.fluid

  return (
    <BackgroundImage fluid={imageData} preserveStackingContext>
      <Container>
        <Content>
          <h1>Protecting Your Loved Ones&apos; Financial Future</h1>
          <p>
            We believe in securing your legacy beyond your lifetime.
            Safeguarding your family&apos;s financial future is now simpler than
            ever.
          </p>
          <ButtonContainer>
            <StyledButton
              label="Explore Our Products"
              link="/products"
              filled
              buttonColor={PALETTE.blue}
              textColor="#fff"
              altColor="#fff"
            />
            <StyledButton
              label="CONTACT US"
              link="/contact-us"
              filled
              buttonColor="#fff"
              textColor={PALETTE.blue}
            />
          </ButtonContainer>
        </Content>
      </Container>
    </BackgroundImage>
  )
}

export default Banner

const Container = styled.div`
  background-color: #00000050;
  padding: 60px 30px 100px 30px;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    padding: 60px 50px;
  }

  @media screen and (min-width: ${theme.breakpoints.md}) {
    background-color: transparent;
    padding: 120px 104px;
  }

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    background-color: transparent;
  }
`

const Content = styled.div`
  color: ${props => props.theme.palette.secondary.contrastText};
  display: flex;
  flex-direction: column;
  max-width: 610px;
  gap: 40px;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    p {
      font-size: 23px;
      line-height: 31px;
    }
  }

  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin: 0 104px 0 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 34px;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
  }
`
