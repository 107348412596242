import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../components"

const Challenges: React.FC = () => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Your Financial Legacy with Workerslife"
      color="#00427C"
      className="slider"
      description="We firmly believe in financial legacies that transcend your life. With Workerslife, you can secure your family's financial future and leave a lasting impact."
      content={[
        {
          image: (
            <StaticImage
              src="../../assets/images/landing/challenges-1.png"
              {...ImageProperties}
            />
          ),
          topic: "Dignity Achieved Through Financial Security",
          label:
            "Our insurance products are designed to provide your loved ones with the financial security they deserve, ensuring a dignified and stress-free future.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/landing/challenges-2.png"
              {...ImageProperties}
            />
          ),
          topic: "Free and Fair Access to Financial Solutions",
          label:
            "We're committed to providing you with affordable, transparent, and accessible financial solutions that cater to your unique needs.",
        },
        {
          image: (
            <StaticImage
              src="../../assets/images/landing/challenges-3.png"
              {...ImageProperties}
            />
          ),
          topic: "Quick Payment of Claims",
          label:
            "In times of need, you can rely on us for a swift and efficient claims process. Your peace of mind is our priority.",
        },
      ]}
    />
  )
}

export default Challenges
