import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import {
  Banner,
  Challenges,
  Conclusion,
  Customise,
  Legacy,
  Local,
} from "../sections/landing"
import { LocalBusinessJsonLd } from "gatsby-plugin-next-seo"

const IMAGE =
  "https://www.workerslife.co.za/static/b368176929fd6f7f32c318ca7aa9e1f3/4693b/banner.webp"

const IndexPage: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Insurance Solutions"
        description="Explore comprehensive insurance solutions for life, funeral, and more. Secure your family's future with Workerslife. Visit us today."
        image={IMAGE}
      />
      <LocalBusinessJsonLd
        id="https://www.workerslife.co.za/"
        type="FinancialService"
        name="Workerslife"
        description="Workerslife offers life and non-life products, including value-added services, designed to secure your family’s financial future after you pass."
        url="https://www.workerslife.co.za/contact-us"
        telephone="+27861520520"
        address={{
          streetAddress: "273 Paul Kruger Street",
          addressLocality: "Pretoria",
          addressRegion: "Gauteng",
          postalCode: "0001",
          addressCountry: "ZA",
        }}
        geo={{
          longitude: "-25.748594579276602",
          latitude: "28.187964738501133",
        }}
        images={[IMAGE]}
      />
      <Banner />
      <p className="italic">
        Welcome to Workerslife, where your family&apos;s financial well-being is
        our utmost priority. We understand that the people who matter most to
        you are your family and loved ones. Ensuring their financial future is
        as straightforward as 1-2-3.
      </p>
      <Legacy />
      <Challenges />
      <Customise />
      <Local />
      <Conclusion />
    </PageWrapper>
  )
}

export default IndexPage

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 70px;
  .italic {
    font-style: italic;
    text-align: center;
    padding: 0 10%;
  }
`
