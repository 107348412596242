import React from "react"
import styled from "styled-components"
import { SectionHeader } from "../../components"
import theme from "../../theme"
import { StyledButton } from "../../elements"

const PALETTE = theme.palette.custom

const Conclusion: React.FC = () => {
  return (
    <Container>
      <SectionHeader
        title="Securing Your Legacy with Workerslife"
        showButton={false}
      />
      <p>
        Workerslife is not just an insurance provider; we&apos;re your partner
        in securing your legacy. With us, leaving a financial inheritance for
        your loved ones is as simple as building your cover and contacting us.
        Join the thousands who have entrusted us with their financial future.
        Build your legacy with Workerslife today.
      </p>
      <ButtonContainer>
        <StyledButton
          label="Explore Our Products"
          link="/products"
          filled
          buttonColor={PALETTE.blue}
          textColor="#fff"
        />
        <StyledButton
          label="CONTACT US"
          link="/contact-us"
          buttonColor={PALETTE.blue}
          textColor={PALETTE.blue}
        />
      </ButtonContainer>
      <p className="styledFooter">
        With Workerslife, securing generational wealth transfer is not just a
        goal; it&apos;s a reality. Your loved ones deserve nothing less.
      </p>
    </Container>
  )
}

export default Conclusion

const Container = styled.div`
  padding: 0 20px;
  .styledFooter {
    font-style: italic;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 34px;
  justify-content: center;
  margin: 30px auto;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    flex-direction: row;
    margin: 60px auto;
  }
`
