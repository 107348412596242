import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import theme from "../../theme"
import "swiper/css/autoplay"

const PALETTE = theme.palette.custom

const PRODUCTS = [
  {
    color: PALETTE.red,
    title: "Life Insurance in South Africa",
    description: `We understand the unique insurance needs of South Africans and provide solutions that reflect our local expertise.`,
  },
  {
    color: PALETTE.yellow,
    title: "Affordable Funeral Insurance in Pretoria",
    description: `Affordability is key. Our Funeral Insurance in Pretoria is designed to be accessible to everyone.`,
  },
  {
    color: PALETTE.green,
    title: "Short-Term Insurance Companies in Johannesburg",
    description: `In the heart of Johannesburg, we're your go-to for reliable short-term insurance solutions.`,
  },
  {
    color: PALETTE.blue,
    title: "Funeral Insurance Near Me",
    description: `We're closer than you think. Find us for all your funeral insurance needs.`,
  },
  {
    color: PALETTE.red,
    title: "Best Short-Term Insurance in Pretoria",
    description: `Experience the best short-term insurance in Pretoria with Workerslife.`,
  },
  {
    color: PALETTE.green,
    title: "Funeral Cover Policies in Gauteng",
    description: `Our Funeral Cover policies are designed to meet the specific needs of Gauteng residents.`,
  },
]

const Local: React.FC = () => {
  const renderText = (index: number) => {
    const product = PRODUCTS[index]

    return (
      product && (
        <SlideContent>
          <BackDrop color={product.color || PALETTE.yellow} />
          <div>
            <div className="textContainer">
              <h3>{product.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: product.description }} />
            </div>
          </div>
        </SlideContent>
      )
    )
  }

  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  const [swiper, setSwiper] = useState({})

  return (
    <Container>
      <Left>
        <Swiper
          loop={true}
          autoplay
          draggable={true}
          grabCursor={true}
          onInit={ev => {
            setSwiper(ev)
          }}
        >
          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-1.png"
              {...ImageProperties}
            />
            {renderText(0)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-2.png"
              {...ImageProperties}
            />
            {renderText(1)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-3.png"
              {...ImageProperties}
            />
            {renderText(2)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-4.png"
              {...ImageProperties}
            />
            {renderText(3)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-5.png"
              {...ImageProperties}
            />
            {renderText(4)}
          </SwiperSlide>

          <SwiperSlide>
            <StaticImage
              src="../../assets/images/landing/legacy-6.png"
              {...ImageProperties}
            />
            {renderText(5)}
          </SwiperSlide>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              swiper.slideNext()
            }}
          ></div>
        </Swiper>
      </Left>
      <Right>
        <h2>Your Local Insurance Partner</h2>
        <p>
          Workerslife is more than just an insurance provider. We are your local
          partner, deeply rooted in South Africa.
        </p>
      </Right>
    </Container>
  )
}

export default Local

const Container = styled.div`
  display: grid;
  gap: 40px 120px;
  grid-template-rows: auto auto;
  margin: 0 auto;
  max-width: 1000px;

  > div {
    overflow-x: hidden;
  }

  .swiper {
    width: 100%;
  }

  .swiper-wrapper {
    box-sizing: border-box;
    height: inherit;
  }

  .swiper-slide {
    height: inherit;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
  }

  .gatsby-image-wrapper {
    display: none;
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.md}) and (max-width: ${props =>
      props.theme.maxBreakpoints.lg}),
    (min-width: ${props => props.theme.breakpoints.lg}) {
    .gatsby-image-wrapper {
      display: block;
      position: absolute;
      z-index: -1;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 415px;
    grid-template-rows: auto;
    max-width: inherit;
    padding: 0 0 0 80px;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding: 0;
  }
`

const SlideContent = styled.div`
  height: 100%;
  padding: 50px 20px 200px 20px;
  position: relative;
  width: 100%;
  z-index: 2;

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .textContainer {
    color: #fff;
    margin-bottom: 50px;

    p {
      white-space: pre-wrap;

      i {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-bottom: 280px;

    .btnView {
      display: flex;
      margin: auto 0 0 auto;
      width: max-content;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 80px 70px 280px 70px;
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.md}) and (max-width: ${props =>
      props.theme.maxBreakpoints.lg}),
    (min-width: ${props => props.theme.breakpoints.lg}) {
    height: calc(100% - 74px);
    margin: 74px 0 0 220px;
    padding: 80px 70px;
    width: calc(100% - 220px);
  }
`

const BackDrop = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.md}) and (max-width: ${props =>
      props.theme.maxBreakpoints.lg}),
    (min-width: ${props => props.theme.breakpoints.lg}) {
    border-radius: 4px;
  }
`
